const S1_C_USER = 'S1_C_USER';
const S1_U_USER = 'S1_U_USER';
const S1_D_USER = 'S1_D_USER';
//TODO: remove this after the s1 migration is done, including sso clients
const S1_RESET_USER = 'S1_RESET_USER';
const TIR_USER = 'TIR_USER';
const TIR_RTT_USER = 'TIR_RTT_USER';
const TIR_CORP = 'TIR_CORP';
const TIR_ENT = 'TIR_ENT';
const TIR_ENT_SECURITY_ADMIN = 'TIR_ENT_SECURITY_ADMIN';
const TIR_R_PRINT_PREVIEW = 'TIR_R_PRINT_PREVIEW';
const TIR_R_RECORD = 'TIR_R_RECORD';
const TIR_C_RECORD = 'TIR_C_RECORD';
const TIR_U_RECORD = 'TIR_U_RECORD';
const TIR_D_RECORD = 'TIR_D_RECORD';
const TIR_R_RECORD_PAYER = 'TIR_R_RECORD_PAYER';
const TIR_C_RECORD_PAYER = 'TIR_C_RECORD_PAYER';
const TIR_U_RECORD_PAYER = 'TIR_U_RECORD_PAYER';
const TIR_D_RECORD_PAYER = 'TIR_D_RECORD_PAYER';
const TIR_R_RECORD_PAYEE = 'TIR_R_RECORD_PAYEE';
const TIR_C_RECORD_PAYEE = 'TIR_C_RECORD_PAYEE';
const TIR_U_RECORD_PAYEE = 'TIR_U_RECORD_PAYEE';
const TIR_D_RECORD_PAYEE = 'TIR_D_RECORD_PAYEE';
const TIR_R_RECORD_WITHHOLDING = 'TIR_R_RECORD_WITHHOLDING';
const TIR_C_RECORD_WITHHOLDING = 'TIR_C_RECORD_WITHHOLDING';
const TIR_U_RECORD_WITHHOLDING = 'TIR_U_RECORD_WITHHOLDING';
const TIR_D_RECORD_WITHHOLDING = 'TIR_D_RECORD_WITHHOLDING';
const TIR_R_RECORD_NON_WITHHOLDING = 'TIR_R_RECORD_NON_WITHHOLDING';
const TIR_C_RECORD_NON_WITHHOLDING = 'TIR_C_RECORD_NON_WITHHOLDING';
const TIR_U_RECORD_NON_WITHHOLDING = 'TIR_U_RECORD_NON_WITHHOLDING';
const TIR_D_RECORD_NON_WITHHOLDING = 'TIR_D_RECORD_NON_WITHHOLDING';
const TIR_U_RECORD_NON_TAX_FIELDS = 'TIR_U_RECORD_NON_TAX_FIELDS';
const TIR_C_MAP = 'TIR_C_MAP';
const TIR_C_IMPORT = 'TIR_C_IMPORT';
const TIR_D_CANCEL_IMPORT = 'TIR_D_CANCEL_IMPORT';
const TIR_D_BACK_OUT_IMPORT = 'TIR_D_BACK_OUT_IMPORT';
const TIR_R_VERIFY_IDENTITY = 'TIR_R_VERIFY_IDENTITY';
const TIR_C_PRINT_FILE = 'TIR_C_PRINT_FILE';
const TIR_C_BNOTICE = 'TIR_C_BNOTICE';
const TIR_C_W9 = 'TIR_C_W9';
const TIR_D_CANCEL_PRINT = 'TIR_D_CANCEL_PRINT';
const TIR_C_APPROVE_PRINT = 'TIR_C_APPROVE_PRINT';
const TIR_C_REPRINT = 'TIR_C_REPRINT';
const TIR_C_REPRINT_LOCALLY = 'TIR_C_REPRINT_LOCALLY';
const TIR_C_TRANSMITTAL_FILE = 'TIR_C_TRANSMITTAL_FILE';
const TIR_U_APPROVE_TRANSMITTAL_FILE = 'TIR_U_APPROVE_TRANSMITTAL_FILE';
const TIR_FM_ENTERDIR_TRANSMITTALFILES = 'TIR_FM_ENTERDIR_TRANSMITTALFILES';
const TIR_FM_ENTERDIR_REPORTS = 'TIR_FM_ENTERDIR_REPORTS';
const TIR_FM_ENTERDIR_PRINTFILES = 'TIR_FM_ENTERDIR_PRINTFILES';
const TIR_FM_ENTERDIR_UPLOADED = 'TIR_FM_ENTERDIR_UPLOADED';
const TIR_FM_ENTERDIR_TIMIMAGES = 'TIR_FM_ENTERDIR_TIMIMAGES';
const TIR_FM_LISTDIRFILES_TRANSMITTALFILES = 'TIR_FM_LISTDIRFILES_TRANSMITTALFILES';
const TIR_FM_LISTDIRFILES_REPORTS = 'TIR_FM_LISTDIRFILES_REPORTS';
const TIR_FM_LISTDIRFILES_PRINTFILES = 'TIR_FM_LISTDIRFILES_PRINTFILES';
const TIR_FM_LISTDIRFILES_UPLOADED = 'TIR_FM_LISTDIRFILES_UPLOADED';
const TIR_FM_LISTDIRFILES_TIMIMAGES = 'TIR_FM_LISTDIRFILES_TIMIMAGES';
const TIR_FM_WRITETODIR_TRANSMITTALFILES = 'TIR_FM_WRITETODIR_TRANSMITTALFILES';
const TIR_FM_WRITETODIR_REPORTS = 'TIR_FM_WRITETODIR_REPORTS';
const TIR_FM_WRITETODIR_PRINTFILES = 'TIR_FM_WRITETODIR_PRINTFILES';
const TIR_FM_WRITETODIR_UPLOADED = 'TIR_FM_WRITETODIR_UPLOADED';
const TIR_FM_WRITETODIR_TIMIMAGES = 'TIR_FM_WRITETODIR_TIMIMAGES';
const TIR_FM_READDIRFILES_TRANSMITTALFILES = 'TIR_FM_READDIRFILES_TRANSMITTALFILES';
const TIR_FM_READDIRFILES_REPORTS = 'TIR_FM_READDIRFILES_REPORTS';
const TIR_FM_READDIRFILES_PRINTFILES = 'TIR_FM_READDIRFILES_PRINTFILES';
const TIR_FM_READDIRFILES_UPLOADED = 'TIR_FM_READDIRFILES_UPLOADED';
const TIR_FM_READDIRFILES_TIMIMAGES = 'TIR_FM_READDIRFILES_TIMIMAGES';
const TIR_FM_DELFROMDIR_TRANSMITTALFILES = 'TIR_FM_DELFROMDIR_TRANSMITTALFILES';
const TIR_FM_DELFROMDIR_REPORTS = 'TIR_FM_DELFROMDIR_REPORTS';
const TIR_FM_DELFROMDIR_PRINTFILES = 'TIR_FM_DELFROMDIR_PRINTFILES';
const TIR_FM_DELFROMDIR_UPLOADED = 'TIR_FM_DELFROMDIR_UPLOADED';
const TIR_FM_DELFROMDIR_TIMIMAGES = 'TIR_FM_DELFROMDIR_TIMIMAGES';
const TIR_U_ATTACH_DOCUMENT = 'TIR_U_ATTACH_DOCUMENT';
const TIR_C_APPROVE_TRANSMITTAL = 'TIR_C_APPROVE_TRANSMITTAL';
const TIR_D_CANCEL_TRANSMITTAL = 'TIR_D_CANCEL_TRANSMITTAL';
const TIR_U_COMPLETE_STATE_TRANSMITTAL = "TIR_U_COMPLETE_STATE_TRANSMITTAL";
const TIR_U_APPROVE_STATE_TRANSMITTAL = "TIR_U_APPROVE_STATE_TRANSMITTAL";
const TIR_C_ID_VERIFY = 'TIR_C_ID_VERIFY';
const TIR_C_REPORT = 'TIR_C_REPORT';
const TIR_ADMIN = 'TIR_ADMIN';
const TIR_C_NOTIFICATION = 'TIR_C_NOTIFICATION';
const TIR_C_APP_TAX_YEARS = 'TIR_C_APP_TAX_YEARS';
const TIR_C_TAX_YEAR = 'TIR_C_TAX_YEAR';
const TIR_DEVELOPER = 'TIR_DEVELOPER';
const TIR_U_APPROVE_CLIENT_TCC_XMIT = 'TIR_U_APPROVE_CLIENT_TCC_XMIT';
const TIR_U_COMPLETE_CLIENT_TCC_XMIT = 'TIR_U_COMPLETE_CLIENT_TCC_XMIT';
const TIR_MANAGE_TAX_YEARS = 'TIR_MANAGE_TAX_YEARS';
const TIR_C_SOLICITATION = 'TIR_C_SOLICITATION';
const TIR_R_BULK_TIN = 'TIR_R_BULK_TIN';
const TIR_C_BULK_TIN = 'TIR_C_BULK_TIN';
const TIR_C_USER = 'TIR_C_USER';
const TIR_U_USER = 'TIR_U_USER';
const TIR_D_USER = 'TIR_D_USER';
const TIR_RESET_USER = 'TIR_RESET_USER';
const TIR_NAVIGATE_RTT_LANDING_PAGE = 'TIR_NAVIGATE_RTT_LANDING_PAGE';
const TIR_NAVIGATE_WF_LANDING_PAGE ='TIR_NAVIGATE_WF_LANDING_PAGE';
const TIR_FULL_INTERNAL_ADMIN = 'TIR_FULL_INTERNAL_ADMIN';
const TIR_FULL_INTERNAL_DEVELOPER = 'TIR_FULL_INTERNAL_DEVELOPER';
const TIR_U_SUBSCRIPTION = 'TIR_U_SUBSCRIPTION';
const TIR_C_ROLE = 'TIR_C_ROLE';
const TIR_U_ROLE = 'TIR_U_ROLE';
const TIR_D_ROLE = 'TIR_D_ROLE';
const TIR_R_ROLE = 'TIR_R_ROLE';
// const TIR_R_PERM = 'TIR_R_PERM';
// const TIR_R_GRANT = 'TIR_R_GRANT';
// const TIR_U_GRANT = 'TIR_U_GRANT';

export {
  S1_C_USER,
  S1_U_USER,
  S1_D_USER,
  S1_RESET_USER,
  TIR_USER,
  TIR_RTT_USER,
  TIR_CORP,
  TIR_ENT,
  TIR_ENT_SECURITY_ADMIN,
  TIR_R_PRINT_PREVIEW,
  TIR_R_RECORD,
  TIR_C_RECORD,
  TIR_U_RECORD,
  TIR_D_RECORD,
  TIR_R_RECORD_PAYER,
  TIR_C_RECORD_PAYER,
  TIR_U_RECORD_PAYER,
  TIR_D_RECORD_PAYER,
  TIR_R_RECORD_PAYEE,
  TIR_C_RECORD_PAYEE,
  TIR_U_RECORD_PAYEE,
  TIR_D_RECORD_PAYEE,
  TIR_R_RECORD_WITHHOLDING,
  TIR_C_RECORD_WITHHOLDING,
  TIR_U_RECORD_WITHHOLDING,
  TIR_D_RECORD_WITHHOLDING,
  TIR_R_RECORD_NON_WITHHOLDING,
  TIR_C_RECORD_NON_WITHHOLDING,
  TIR_U_RECORD_NON_WITHHOLDING,
  TIR_D_RECORD_NON_WITHHOLDING,
  TIR_U_RECORD_NON_TAX_FIELDS,
  TIR_C_MAP,
  TIR_C_IMPORT,
  TIR_D_CANCEL_IMPORT,
  TIR_D_BACK_OUT_IMPORT,
  TIR_R_VERIFY_IDENTITY,
  TIR_C_PRINT_FILE,
  TIR_C_BNOTICE,
  TIR_C_W9,
  TIR_D_CANCEL_PRINT,
  TIR_C_APPROVE_PRINT,
  TIR_C_REPRINT,
  TIR_C_REPRINT_LOCALLY,
  TIR_C_TRANSMITTAL_FILE,
  TIR_U_APPROVE_TRANSMITTAL_FILE,
  TIR_U_COMPLETE_STATE_TRANSMITTAL,
  TIR_U_APPROVE_STATE_TRANSMITTAL,
  TIR_FM_ENTERDIR_TRANSMITTALFILES,
  TIR_FM_ENTERDIR_REPORTS,
  TIR_FM_ENTERDIR_PRINTFILES,
  TIR_FM_ENTERDIR_UPLOADED,
  TIR_FM_ENTERDIR_TIMIMAGES,
  TIR_FM_LISTDIRFILES_TRANSMITTALFILES,
  TIR_FM_LISTDIRFILES_REPORTS,
  TIR_FM_LISTDIRFILES_PRINTFILES,
  TIR_FM_LISTDIRFILES_UPLOADED,
  TIR_FM_LISTDIRFILES_TIMIMAGES,
  TIR_FM_WRITETODIR_TRANSMITTALFILES,
  TIR_FM_WRITETODIR_REPORTS,
  TIR_FM_WRITETODIR_PRINTFILES,
  TIR_FM_WRITETODIR_UPLOADED,
  TIR_FM_WRITETODIR_TIMIMAGES,
  TIR_FM_READDIRFILES_TRANSMITTALFILES,
  TIR_FM_READDIRFILES_REPORTS,
  TIR_FM_READDIRFILES_PRINTFILES,
  TIR_FM_READDIRFILES_UPLOADED,
  TIR_FM_READDIRFILES_TIMIMAGES,
  TIR_FM_DELFROMDIR_TRANSMITTALFILES,
  TIR_FM_DELFROMDIR_REPORTS,
  TIR_FM_DELFROMDIR_PRINTFILES,
  TIR_FM_DELFROMDIR_UPLOADED,
  TIR_FM_DELFROMDIR_TIMIMAGES,
  TIR_U_ATTACH_DOCUMENT,
  TIR_C_APPROVE_TRANSMITTAL,
  TIR_D_CANCEL_TRANSMITTAL,
  TIR_C_ID_VERIFY,
  TIR_C_REPORT,
  TIR_ADMIN,
  TIR_C_NOTIFICATION,
  TIR_C_APP_TAX_YEARS,
  TIR_C_TAX_YEAR,
  TIR_DEVELOPER,
  TIR_U_APPROVE_CLIENT_TCC_XMIT,
  TIR_U_COMPLETE_CLIENT_TCC_XMIT,
  TIR_MANAGE_TAX_YEARS,
  TIR_C_SOLICITATION,
  TIR_R_BULK_TIN,
  TIR_C_BULK_TIN,
  TIR_C_USER,
  TIR_U_USER,
  TIR_D_USER,
  TIR_RESET_USER,
  TIR_NAVIGATE_RTT_LANDING_PAGE,
  TIR_NAVIGATE_WF_LANDING_PAGE,
  TIR_FULL_INTERNAL_ADMIN,
  TIR_FULL_INTERNAL_DEVELOPER,
  TIR_U_SUBSCRIPTION,
  TIR_C_ROLE,
  TIR_U_ROLE,
  TIR_D_ROLE,
  TIR_R_ROLE
};
